import { Box, Button, Paper, Typography, Dialog, DialogActions, DialogContent, DialogTitle, TextField, FormControlLabel, Checkbox } from '@mui/material';
import { styled, Theme } from '@mui/material/styles';

import ComputerOutlinedIcon from '@mui/icons-material/ComputerOutlined'; // System messages
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'; // SMS messages
import MarkEmailReadOutlinedIcon from '@mui/icons-material/MarkEmailReadOutlined'; // Replied SMS messages 

import PhoneEnabledOutlinedIcon from '@mui/icons-material/PhoneEnabledOutlined';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';

import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined'; // No appointment
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined'; // Appointment set

import { useState } from 'react';
import axios from 'axios';
import { User, useAuth } from '../../context/AuthContext';
import { statusOptions } from '../../types/LeadTypes';
import { Link } from 'react-router-dom';

const NotesIconHeadingBox = styled(Box)`
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-left: 10px;
  width: Calc(100% - 12px);
  margin-bottom: -1px
`
const NotesIconFlex = styled(Box)`
  position: relative;
  display: flex;
`
const NotesIconButton = styled(Button)`
&&{
  position: relative;
  display: flex;
  min-width: 32px;
  width: 32px;
  height: 28px;
  border-radius: 8px;
  justify-content: center;
}`
const HoverOptions = styled(Box)`
  position: absolute;
  display: flex;
  top: 28px;
  left: 0;
  flex-direction: column;
  background: rgba(0,0,0,0.8);
  padding: 5px;
  border-radius: 12px;
  z-index: 10;
  button{
    font-size: 11px;
    border: 1px solid transparent;
    &:hover{
      border: 1px solid #B9E123;
    }
  }
`
const OptionHeading = styled(Typography)`
&&{  
  color: #FFF;
  font-size: 11px;
  text-align: center;
  margin-bottom: -2px;
}`

interface NotesStateProps {
  isSelected: boolean;
}

const InternalNotesState = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})<NotesStateProps>(({ isSelected }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '40px',
  height: '30px',
  textAlign: 'center',
  color: isSelected ? '#ACD513' : 'grey',
  backgroundColor: isSelected ? 'white' : '#F9F9F9',
  zIndex: 2,
  border: `1px solid #EAEAEA`,
  borderTopLeftRadius: '12px',
  borderTopRightRadius: '12px',
  borderBottom: isSelected ? '1px solid white' : 'none',
  cursor: isSelected ? 'default' : 'pointer',
}));

const SmsNotesState = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})<NotesStateProps>(({ isSelected }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '40px',
  height: '30px',
  textAlign: 'center',
  color: isSelected ? '#007FFF' : 'grey',
  backgroundColor: isSelected ? 'white' : '#F9F9F9',
  zIndex: 2,
  border: `1px solid #EAEAEA`,
  borderTopLeftRadius: '12px',
  borderTopRightRadius: '12px',
  borderBottom: isSelected ? '1px solid white' : 'none',
  cursor: isSelected ? 'default' : 'pointer',
}));

const StatusHeading = styled(Button)`
&&{
  min-width: 50px;
  font-weight: 600;
  height: 28px;
  padding: 0 4px;
  border-radius: 8px;
}`;

const StyledDialog = styled(Dialog)`
  display: block;
  max-width: 600px;
  margin: 0 auto;
`
const StyledTextField = styled(TextField)(({ theme }: { theme: Theme }) => ({
  '& .MuiInputBase-root': {
    background: '#F1F1F1', 
  },
}));

interface NotesIconHeadingProps {
  user: User | null;
  leadId: string;
  activeSection: "notes" | "sms";    
  onSectionToggle: (section: "notes" | "sms") => void;
  smsStatus?: string;
  fetchNotes: () => void;
  phoneStatus: string;
  hasPhotos: boolean;
  messageStatus: string;
  appointmentStatus: string;
  leadStatus: string;
  systemSelected?: boolean;
  smsSelected?: boolean;
  minimal: boolean;
  // Email template content
  firstName?: string;
  year?: string;
  make?: string;
  model?: string;
  eid?: string;
  // Expand section (mobile)
  toggleCarExpanded: () => void;
}

const API_PREFIX = process.env.REACT_APP_SERVER_API_URL;

const NotesIconHeading: React.FC<NotesIconHeadingProps> = ({ user, leadId, activeSection, onSectionToggle, smsStatus, fetchNotes, phoneStatus, hasPhotos, messageStatus, appointmentStatus, leadStatus, minimal = false, firstName, year, make, model, eid, toggleCarExpanded }) => {
  const [displayCallOptions, setDisplayCallOptions] = useState(false);
  const [displayPhotoOptions, setDisplayPhotoOptions] = useState(false);
  const [displayAppointmentOptions, setDisplayAppointmentOptions] = useState(false);
  const [displayStatusOptions, setDisplayStatusOptions] = useState(false);  
  
  // const toggleCallOptions = () => setDisplayCallOptions(!showCallOptions);
  const showCallOptions = () => setDisplayCallOptions(true);
  const hideCallOptions = () => setDisplayCallOptions(false);
  const [phoneStatusIcon, setPhoneIconStatus] = useState(!phoneStatus || phoneStatus === 'None' ? 'None' : phoneStatus);

  // const togglePhotoOptions = () => setDisplayPhotoOptions(!showPhotoOptions);
  const showPhotoOptions = () => setDisplayPhotoOptions(true);
  const hidePhotoOptions = () => setDisplayPhotoOptions(false);

  // const toggleAppointmentOptions = () => setDisplayAppointmentOptions(!showAppointmentOptions);
  const showAppointmentOptions = () => setDisplayAppointmentOptions(true);
  const hideAppointmentOptions = () => setDisplayAppointmentOptions(false);


  // const toggleStatusOptions = () => setDisplayStatusOptions(!showStatusOptions);
  const showStatusOptions = () => setDisplayStatusOptions(true);
  const hideStatusOptions = () => setDisplayStatusOptions(false);
  const [leadStatusDisplay, setLeadStatusDisplay] = useState(!leadStatus || leadStatus === 'New' ? 'New' : leadStatus);

  const hasAppointment = appointmentStatus === 'Has Appointment';

  // Photo request functions
  const [photoDialogOpen, setPhotoDialogOpen] = useState(false);
  const [photoRequestMessage, setPhotoRequestMessage] = useState('Thank you for choosing Car Buyers Online. We\'d love to help you sell your car! To help us source the best price from our buying network, please upload photos of your vehicle using the button below. We look forward to assisting you in getting the best offer for your vehicle.');
  const [photoRequestMethod, setPhotoRequestMethod] = useState('Email');

  // Appointment request functions
  const [appointmentDialogOpen, setAppointmentDialogOpen] = useState(false);
  const [appointmentRequestMessage, setAppointmentRequestMessage] = useState('Thank you for choosing Car Buyers Online. We\'d love to help you sell your car! To book an appointment, visit the link below.');
  const [appointmentRequestMethod, setAppointmentRequestMethod] = useState('Email');
  const [allowHomeAppointment, setAllowHomeAppointment] = useState(false);
  
  // Function to call the API to add a call status
  const handleCallOptionClick = async (callText: string) => {
    try {
      const response = await axios.post(`${API_PREFIX}/add-call-note`, {
        leadId,
        callText,
        sender: user?.name || 'Unknown'
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}` // Assume token is stored in localStorage
        }
      });

      if (callText === 'Made contact' || callText === 'Photos requested' || callText === 'Left a message') {
        setPhoneIconStatus('Call');
      }else if (callText === 'No answer' ) {
        setPhoneIconStatus('AttemptedCall');
      }
      if (response.status === 201) {
        console.log("Triggering refresh");
        fetchNotes();
        hideCallOptions();
      }

      console.log("Call note added successfully!", response.data);
    } catch (error) {
      console.error("Failed to add call note.", error );
    }
  };

  const handlePhotoOptionClick = () => {
    setPhotoRequestMethod('Email');
    setPhotoDialogOpen(true);
  };

  // This will need to be set to handle request methods of Email and SMS iin future
  const handleSendPhotoRequest = async () => {
    try {
      const response = await axios.post(
        `${API_PREFIX}/send-photo-request`,
        {
          leadId,
          requestType: 'Email', // hard coded to email for now, update when SMS is available
          sender: user?.name || 'Unknown',
          message: photoRequestMessage,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );

      if (response.status === 201) {
        console.log('Triggering refresh');
        fetchNotes();
        setPhotoDialogOpen(false);
      }

      console.log('Photo request sent successfully!', response.data);
    } catch (error) {
      console.error('Failed to send photo request.', error);
    }
  };

  const handleAppointmentOptionClick = () => {
    setAppointmentRequestMethod('Email');
    setAppointmentDialogOpen(true);
  };

  // This will need to be set to handle request methods of Email and SMS iin future
  const handleSendAppointmentRequest = async () => {
    try {
      const response = await axios.post(
        `${API_PREFIX}/send-appointment-request`,
        {
          leadId,
          requestType: 'Email', // hard coded to email for now, update when SMS is available
          sender: user?.name || 'Unknown',
          message: appointmentRequestMessage,
          homeAppointment: allowHomeAppointment,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );

      if (response.status === 201) {
        console.log('Triggering refresh');
        fetchNotes();
        setAppointmentDialogOpen(false);
      }

      console.log('Appointment request sent successfully!', response.data);
    } catch (error) {
      console.error('Failed to send appointment request.', error);
    }
  };

  const handleCloseDialog = () => {
    setPhotoDialogOpen(false);
    setAppointmentDialogOpen(false);
  };

  const handleStatusOptionClick = async (newLeadStatus: string) => {
    try {
      const response = await axios.post(`${API_PREFIX}/update-lead-status`, {
        leadId,
        newLeadStatus,
        sender: user?.name || 'Unknown'
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}` // Assume token is stored in localStorage
        }
      });
      setLeadStatusDisplay(newLeadStatus); // Set lead status optimistically
      hideStatusOptions();
      if (response.status === 201) {
        console.log("Status updated: ", newLeadStatus);
        fetchNotes();
      }

      console.log("Photo request sent successfully!", response.data);
    } catch (error) {
      console.error("Failed to send photo request.", error );
    }
  };

  // Function to get the borderColor for a given leadStatus
  const getBorderColorForStatus = (status: string): string => {
    const statusOption = statusOptions.find(option => option.value === status);
    return statusOption ? statusOption.color : 'grey'; // Default to grey if not found
  };

  return (
    <NotesIconHeadingBox>
      <StyledDialog open={photoDialogOpen} onClose={handleCloseDialog}>
        <DialogTitle style={{ fontWeight: 'bold' }}>Send Email: Photo Request</DialogTitle>
        <DialogContent style={{ overflow: 'initial' }}>
          <Typography style={{ marginBottom: '20px', fontSize: '16px' }}>This will send a photo-upload request email, to the customer, with the following message:</Typography>
          <Typography style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '20px' }}>Photos of your {year ?? ''} {make ?? ''} {model ?? ''}</Typography>
          <Typography style={{ fontSize: '17px', marginBottom: '20px' }}>Hi {firstName},</Typography>
          <StyledTextField 
            fullWidth
            label="Edit Message"
            multiline
            rows={6}
            variant="outlined"
            value={photoRequestMessage}
            onChange={(e) => setPhotoRequestMessage(e.target.value)}
            style={{ marginBottom: '15px' }}
          />
          <Typography style={{ fontSize: '17px', marginBottom: '20px' }}>Please upload photos of your vehicle using the button below:</Typography>
          {eid ? (
          <Link to={`https://carbuyersonline.com.au/image-upload?id=${eid}`} target="_blank">
            <Button style={{ fontSize: '15px', marginBottom: '20px', backgroundColor: '#B9E123', color: 'white' }}>Upload Photos</Button>
          </Link>
          ) : (
            <Typography style={{ fontSize: '17px', marginBottom: '20px', color: 'orange' }}>Error: This customer cannot be found in the database. The photo upload link will not be sent.</Typography>
          )}
        </DialogContent>
        <DialogActions style={{ marginBottom: '20px' }}>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleSendPhotoRequest} color="primary" variant="contained">
            Send Email
          </Button>
        </DialogActions>
      </StyledDialog>
      <StyledDialog open={appointmentDialogOpen} onClose={handleCloseDialog}>
        <DialogTitle style={{ fontWeight: 'bold' }}>Send Email: Appointment Request</DialogTitle>
        <DialogContent style={{ overflow: 'initial' }}>
          <Typography style={{ marginBottom: '20px', fontSize: '16px' }}>This will send an appointment request email, to the customer, with the following message:</Typography>
          <Typography style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '20px' }}>Appointment to sell your {year ?? ''} {make ?? ''} {model ?? ''}</Typography>
          <Typography style={{ fontSize: '17px', marginBottom: '20px' }}>Hi {firstName},</Typography>
          <StyledTextField
            fullWidth
            label="Edit Message"
            multiline
            rows={6}
            variant="outlined"
            value={appointmentRequestMessage}
            onChange={(e) => setAppointmentRequestMessage(e.target.value)}
            style={{ marginBottom: '15px' }}
          />
          <Typography style={{ fontSize: '17px', marginBottom: '20px' }}>Please create an appointment using the button below:</Typography>
          {eid ? (
          <Link to={`https://carbuyersonline.com.au/book-appointment-resume?id=${eid}${allowHomeAppointment ? '&location=all' : ''}`} target="_blank">
            <Button style={{ fontSize: '15px', marginBottom: '20px', backgroundColor: '#B9E123', color: 'white' }}>Create Appointment</Button>
          </Link>
          ) : (
            <Typography style={{ fontSize: '17px', marginBottom: '20px', color: 'orange' }}>Error: This customer cannot be found in the database. The appointment email will not be sent.</Typography>
          )}
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={allowHomeAppointment}
                  onChange={(e) => setAllowHomeAppointment(e.target.checked)}
                  color="primary"
                />
              }
              label="Allow own address appointment"
            />
          </Box>
        </DialogContent>
        <DialogActions style={{ marginBottom: '20px' }}>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleSendAppointmentRequest} color="primary" variant="contained">
            Send Email
          </Button>
        </DialogActions>
      </StyledDialog>
      <NotesIconFlex>
        {!minimal ? (
          <>
            <InternalNotesState isSelected={activeSection === "notes"} onClick={() => onSectionToggle("notes")}>
              <ComputerOutlinedIcon style={{ width: '22px' }} />
            </InternalNotesState>
            <SmsNotesState isSelected={activeSection === "sms"} onClick={() => onSectionToggle("sms")}>
              {smsStatus === 'replied' ? (
                  <MarkEmailReadOutlinedIcon style={{ width: '22px' }} />
                ) : (
                  <EmailOutlinedIcon style={{ width: '22px' }} />
                )}
            </SmsNotesState>
          </>
        ): (
          <>
            {smsStatus === 'replied' && (
              <MarkEmailReadOutlinedIcon style={{ width: '22px', color: 'rgb(128,128,128)' }} onClick={() => { onSectionToggle("sms"); toggleCarExpanded(); }} />
            )}
          </>
        )}
      </NotesIconFlex>
      <NotesIconFlex style={{ marginTop: '2px', marginBottom: '-2px' }}>
        <NotesIconButton
          onMouseEnter={showCallOptions}
          onMouseLeave={hideCallOptions}
          onClick={showCallOptions}
          >
          <PhoneEnabledOutlinedIcon style={{ color: phoneStatusIcon === 'Call' ? '#ACD513' : phoneStatusIcon === 'AttemptedCall' ? 'orange' : 'grey', width: '20px' }} />
        </NotesIconButton>
          {displayCallOptions && <HoverOptions 
            onMouseEnter={showCallOptions}
            onMouseLeave={hideCallOptions}
            style={{ width: '120px', marginLeft: '-50px' }}
            >
          <OptionHeading>Add note:</OptionHeading>
          <Button onClick={() => handleCallOptionClick('Made contact')}>Made contact</Button>
          <Button onClick={() => handleCallOptionClick('Photos requested')}>Photos requested</Button>
          <Button onClick={() => handleCallOptionClick('Left a message')}>Left a message</Button>
          <Button onClick={() => handleCallOptionClick('No answer')}
            sx={{ 
              borderColor: 'transparent', // Default no border
              '&:hover': {
                borderColor: `orange !important` // Color from the option
              }
            }}>No answer</Button>
        </HoverOptions>}
        <NotesIconButton
          onMouseEnter={showPhotoOptions}
          onMouseLeave={hidePhotoOptions}
          onClick={showPhotoOptions}
          >
          <CameraAltOutlinedIcon style={{ color: hasPhotos ? '#ACD513' : 'grey', width: '22px', marginTop: '1px' }}  />
        </NotesIconButton>
        {displayPhotoOptions && <HoverOptions
            onMouseEnter={showPhotoOptions}
            onMouseLeave={hidePhotoOptions}
            style={{ width: '190px', marginLeft: '-50px' }}
            >
          <OptionHeading>Request photos:</OptionHeading>
          <Button onClick={handlePhotoOptionClick}>Send photo request (Email)</Button>
          {/* <Button onClick={() => handlePhotoOptionClick('SMS')}>Send photo upload link (SMS)</Button> */}
        </HoverOptions>}
        <NotesIconButton
          onMouseEnter={showAppointmentOptions}
          onMouseLeave={hideAppointmentOptions}
          onClick={showAppointmentOptions}
          >
          <CalendarTodayOutlinedIcon style={{ color: hasAppointment ? '#ACD513' : 'grey', width: '20px', marginTop: '-1px' }} />
        </NotesIconButton>
        {displayAppointmentOptions && <HoverOptions
            onMouseEnter={showAppointmentOptions}
            onMouseLeave={hideAppointmentOptions}
            style={{ width: '205px', marginLeft: '-50px' }}
            >
          <OptionHeading>Request appointment:</OptionHeading>
          <Button onClick={handleAppointmentOptionClick}>Send appointment request (Email)</Button>
          {/* <Button onClick={() => handlePhotoOptionClick('SMS')}>Send photo upload link (SMS)</Button> */}
        </HoverOptions>}
        <StatusHeading 
          onMouseEnter={showStatusOptions}
          onMouseLeave={hideStatusOptions}
          onClick={showStatusOptions}
          style={{ color: getBorderColorForStatus(leadStatusDisplay), cursor: 'pointer' }}
          >
          {String(leadStatusDisplay === 'Delete' ? 'Deleted' : leadStatusDisplay).toUpperCase()}
        </StatusHeading>
        {displayStatusOptions && <HoverOptions
            onMouseEnter={showStatusOptions}
            onMouseLeave={hideStatusOptions}
            style={{ width: '100px', left: 'auto', right: 0, marginRight: '-4px'}}
            >
          <OptionHeading>Change status:</OptionHeading>
          {statusOptions.map(option => (
            <Button key={option.value} value={option.value} onClick={() => handleStatusOptionClick(`${option.value}`)} 
              sx={{ 
                borderColor: 'transparent', // Default no border
                '&:hover': {
                  borderColor: `${option.color} !important` // Color from the option
                }
            }}>{option.label}</Button>
          ))}
        </HoverOptions>}
      </NotesIconFlex>
    </NotesIconHeadingBox>
  )
}

export default NotesIconHeading;