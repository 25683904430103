export interface PersonalInfo {
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  postcode: string;
  address?: string;
}

export interface Message { // Used for notes
  id: string;
  text: string;
  noteType: string;
  sender: string;
  postedAt: string;
  status: string;
}

export interface SMSMessage { // Used for messages
  id: string;
  to: string;
  text: string;
  sender: string;
  postedAt: string;
  status: 'sending' | 'sent' | 'failed';
}

export interface Lead {
  id: string;
  uid: string;
  eid: string;
  personalInfo: PersonalInfo;
  year: string;
  make: string;
  model: string;
  badge: string;
  engine: string;
  shape: string;
  transmission: string;
  kilometers: string;
  rego: string;
  condition: string;
  finance: string;
  urgency: string;
  estimatedPLow: string;
  estimatedPHigh: string;
  priceTarget: string;
  createdAt: string;
  updatedAt: string;
  bookmarkUsers: string[];
  images: string[];
  notes: Message[];
  messages?: SMSMessage[];
  progress?: string;
  
  // Status fields
  phoneStatus: string;
  messageStatus: string;
  photoStatus: string;
  appointmentStatus: string;
  leadStatus: string;
  smsStatus: string;

  // Appointment fields
  appointmentLocation: string;
  appointmentDate: string;
  appointmentTime: string;
  
  // Dealer Notes
  dealerNotes?: string;

  // Referrer
  siteURL?: string;
  source?: string;
  referrer?: string;
  valuationEnquiry?: boolean;
}

interface StatusOption {
  value: string;
  label: string;
  color: string;
}

export const statusOptions: StatusOption[] = [
  { value: 'New', label: 'New', color: '#B9E123' }, // green
  // { value: 'VIP', label: 'VIP', color: '#B9E123' }, // green
  { value: 'Working', label: 'Working', color: '#B9E123' }, // working
  { value: 'Appointment', label: 'Appointment', color: '#B9E123' }, // green
  { value: 'Purchased', label: 'Purchased', color: 'grey' }, // grey
  { value: 'Dead', label: 'Dead', color: 'orange' }, 
  { value: 'Duplicate', label: 'Duplicate', color: '#F22020' }, 
  { value: 'Delete', label: 'Delete', color: '#F22020' } // red
];
