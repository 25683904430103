import React, { useEffect, useState, ChangeEvent, useRef } from 'react';
import axios from 'axios';
import { Lead } from '../types/LeadTypes';
import LeadDisplay from '../components/backend/LeadDisplay';
import { Box, Button, CircularProgress, FormControl, InputLabel, Select, SelectChangeEvent, Typography, styled } from '@mui/material';
import { convertFirestoreTimestampToDate } from '../utils/firestoreUtils';
import { API_PREFIX } from '../constants/urls';

import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { StyledDashboardButton } from '../components/theme/DashboardStyles';
import FilterOptions from '../components/backend/FilterOptions';
import { ContentWidth } from '../components/ThemeComponents';

import TuneIcon from '@mui/icons-material/Tune';
import RefreshIcon from '@mui/icons-material/Refresh';
import VisibilityOnIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import AnalyticsMinimal from '../components/theme/AnalyticsMinimal';
import Notifications from '../components/backend/Notifications';
import { useAuth } from '../context/AuthContext';

const SearchFlex = styled(Box)`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 10px;
  @media all and (max-width: 1199px){
    flex-direction: column;
  }
`;
const SortFlex = styled(Box)`
  display: flex;
  justify-content: space-between;
  gap: 10px;
`;

const HeaderArea = styled(Box)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  @media all and (max-width: 599px){
    flex-direction: column;
  }
`

const DashboardPage: React.FC = () => {
  const { user } = useAuth();
  const [leads, setLeads] = useState<Lead[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortField, setSortField] = useState('createdAt');
  const [sortOrder, setSortOrder] = useState('desc');
  const [filterChangeCount, setFilterChangeCount] = useState(0);
  const [filteredYear, setFilteredYear] = useState<string | undefined>('');
  const [filteredMake, setFilteredMake] = useState<string | undefined>('');
  const [filteredModel, setFilteredModel] = useState<string | undefined>('');
  const [filteredOtherStatus, setFilteredOtherStatus] = useState<string | undefined>('');
  const [filteredLeadStatus, setFilteredLeadStatus] = useState<string | undefined>('');
  const [page, setPage] = useState<number>(1);
  const [pageSize] = useState<number>(30);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [lastDocId, setLastDocId] = useState<string | null>(null);
  const [lastPageReached, setLastPageReached] = useState<boolean>(false);
  const [hideSensitive, setHideSensitive] = useState<boolean>(false); 
  const [showNoLeadsMessage, setShowNoLeadsMessage] = useState(false);

  const [showFilters, setShowFilters] = useState<boolean>(() => {
    const storedShowFilters = localStorage.getItem('showFilters');
    return storedShowFilters !== null ? JSON.parse(storedShowFilters) : false;
  });

  const isLoadingRef = useRef(isLoading);
  isLoadingRef.current = isLoading;
  const fetchInProgressRef = useRef(false);

  // Refresh notifications
  const [notificationRefreshTrigger, setNotificationRefreshTrigger] = useState(0);

  // Mobile layout
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1199);

  // Update leads use effect - when the query or sort order is changed
  useEffect(() => {
    console.log("Filters changed to:", { filteredYear, filteredMake, filteredModel, filteredOtherStatus, filteredLeadStatus });
    setPage(1);
    setLastDocId(null);
    setLastPageReached(false);
    const delayDebounceFn = setTimeout(() => {
      fetchLeads({
        query: searchQuery,
        sort: sortField,
        sortOrder,
        page: 1,
        filteredYear,
        filteredMake,
        filteredModel,
        filteredOtherStatus,
        filteredLeadStatus
      });
    }, 200);
    return () => clearTimeout(delayDebounceFn);
  }, [searchQuery, sortField, sortOrder, filteredYear, filteredMake, filteredModel, filteredOtherStatus, filteredLeadStatus, filterChangeCount]);

  // Function to deduplicate leads by their id
  const deduplicateLeads = (newLeads: Lead[]): Lead[] => {
    const allLeads = [...leads, ...newLeads];
    const uniqueLeads = Array.from(new Map(allLeads.map(lead => [lead.id, lead])).values());
    return uniqueLeads;
  };

  // Main fetchLeads function
  const fetchLeads = async (params: {
    query: string,
    sort: string,
    sortOrder: string,
    page: number,
    filteredYear?: string,
    filteredMake?: string,
    filteredModel?: string,
    filteredOtherStatus?: string,
    filteredLeadStatus?: string
  }) => {
    if (isLoading || lastPageReached) return;

    setIsLoading(true);
    const token = localStorage.getItem('token');

    // Log the parameters being sent
    console.log("Fetching leads with params:", params); 

    try {
      const response = await axios.get<Lead[]>(`${API_PREFIX}/leads`, {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          ...params,
          pageSize,
          lastId: lastDocId
        },
      });

      if (response.data.length < pageSize) {
        setLastPageReached(true);
      }

      if (response.data.length > 0) {
        setLastDocId(response.data[response.data.length - 1].id);
        // Don't deduplicate leads for testing purposes
        // setLeads(response.data);
        // Use the deduplicateLeads function to update state
        setLeads(prevLeads => deduplicateLeads([...prevLeads, ...response.data]));
        setPage(prevPage => prevPage + 1);     

      }
      console.log("Fetched leads:", response.data);

    } catch (error) {
      console.error('Error fetching leads:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Adjust the useEffect hook that handles scroll to prevent multiple fetches
  useEffect(() => {
    const handleScroll = () => {
      const nearBottom = Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight - 800;
      if (nearBottom && !isLoadingRef.current && !fetchInProgressRef.current) {
        fetchInProgressRef.current = true;
        fetchLeads({
          query: searchQuery,
          sort: sortField,
          sortOrder,
          page,
          filteredYear,
          filteredMake,
          filteredModel,
          filteredOtherStatus,
          filteredLeadStatus
        }).finally(() => {
          fetchInProgressRef.current = false;
        });
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastPageReached, fetchLeads, searchQuery, sortField, sortOrder, filteredYear, filteredMake, filteredModel, filteredOtherStatus, filteredLeadStatus, page]);


  // Set search query on text change
  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLeads([]); // Clear existing leads
    setPage(1); // Set page to 1
    setLastDocId(null); // Reset pagination continuation
    setLastPageReached(false); // Reset end of data indicator

    setSearchQuery(e.target.value);
  };

  const handleSortChange = (event: SelectChangeEvent<string>) => {
    setLeads([]); // Clear existing leads
    setPage(1); // Set page to 1
    setLastDocId(null); // Reset pagination continuation
    setLastPageReached(false); // Reset end of data indicator

    // Protect against undefined values
    const value = event.target.value || '';
    const [field, order] = value.split('_');
    setSortField(field);
    setSortOrder(order);
  };

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  useEffect(() => {
    localStorage.setItem('showFilters', JSON.stringify(showFilters));
  }, [showFilters]);
  

  const handleFilterChange = ({ year = '', make = '', model = '', leadStatus = '', otherStatus = '' }: { year?: string, make?: string, model?: string, leadStatus?: string, otherStatus?: string }) => {
      setLeads([]); // Clear existing leads
      setPage(1); // Set page to 1
      setLastDocId(null); // Reset pagination continuation
      setLastPageReached(false); // Reset end of data indicator
      setFilterChangeCount(count => count + 1); // Hacky way of forcing the useEffect to update

      setFilteredYear(year);
      setFilteredMake(make);
      setFilteredModel(model);
      setFilteredOtherStatus(otherStatus);
      setFilteredLeadStatus(leadStatus);

      console.log(`Filtered by ${year}, ${make}, ${model}, ${otherStatus}, ${leadStatus}`);
  };

  const refreshData = () => {
    setLeads([]); // Clear existing leads
    setPage(1); // Set page to 1
    setLastDocId(null); // Reset pagination continuation
    setLastPageReached(false); // Reset end of data indicator
    setFilterChangeCount(count => count + 1); // Hacky way of forcing the useEffect to update
    setNotificationRefreshTrigger(trigger => trigger + 1); // Refresh notifications (bell icon)
  };

  // Prevent No leads message showing when refreshing
  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    
    if (leads.length === 0) {
      timeoutId = setTimeout(() => {
        setShowNoLeadsMessage(true);
      }, 400);
    } else {
      setShowNoLeadsMessage(false);
    }

    return () => clearTimeout(timeoutId);
  }, [leads]);

  // Check window width for mobile layout
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1199);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleNotificationClick = (leadId: string) => {
    setSearchQuery(leadId);
    refreshData();
  };

  return (
    <Box>
      <ContentWidth>
        <HeaderArea>
          <Box>
            <h3 style={{ color: '#282828', margin: '10px 0 0', width: '120px' }}>Dashboard</h3>
            { /* hideSensitive ? (
              <Button onClick={() => setHideSensitive(false)} style={{ height: '40px', width: '40px', }}>
                <VisibilityOnIcon style={{ fontSize: '22px' }} />
              </Button>
            ) : (
              <Button onClick={() => setHideSensitive(true)} style={{ height: '40px', width: '40px', }}>
                <VisibilityOffIcon style={{ fontSize: '22px', color: '#959595' }} />
              </Button>
            ) */}
          </Box>
          <Box>
            <AnalyticsMinimal />
          </Box>
        </HeaderArea>
        <SearchFlex>
          <TextField
            fullWidth
            label="Search leads..."
            variant="outlined"
            value={searchQuery}
            onChange={handleSearchChange}
          />
          <SortFlex>
            <FormControl variant="outlined" sx={{ minWidth: 90, width: isMobile ? '100%' : 'auto' }}>
              <InputLabel style={{ background: 'white', padding: '0 10px' }}>Sort By</InputLabel>
              <Select value={`${sortField}_${sortOrder}`} onChange={handleSortChange} style={{ height: '56px', lineHeight: '32px' }}>
                <MenuItem value="createdAt_desc">Date (Latest)</MenuItem>
                <MenuItem value="createdAt_asc">Date (Oldest)</MenuItem>
                <MenuItem value="lastName_asc">Last Name (A-Z)</MenuItem>
                <MenuItem value="lastName_desc">Last Name (Z-A)</MenuItem>
                {/*
                  <MenuItem value="estimate_desc">Estimate (High-Low)</MenuItem>
                  <MenuItem value="estimate_asc">Estimate (Low-High)</MenuItem>
                */}
                <MenuItem value="year_desc">Year (Latest)</MenuItem>
                <MenuItem value="year_asc">Year (Oldest)</MenuItem>
              </Select>
            </FormControl>
            <StyledDashboardButton onClick={refreshData} >
              <RefreshIcon style={{ fontSize: '22px' }} />
            </StyledDashboardButton>
            
            {user?.id && (
              <Notifications userId={user.id} onNotificationClick={handleNotificationClick} refreshTrigger={notificationRefreshTrigger} />
            )}
            <StyledDashboardButton onClick={toggleFilters}>
              <TuneIcon style={{ fontSize: '22px' }} />
            </StyledDashboardButton>
          </SortFlex>
        </SearchFlex>
        {showFilters && (
          <FilterOptions onFilterChange={handleFilterChange} onResetSearchQuery={() => setSearchQuery('')} />
        )}
        {leads.length > 0 && (
          leads.map((lead) => (
            filteredLeadStatus === '' ? ( // Exclude dead, duplicates and deleted entries from the default view
              (lead.leadStatus !== 'Dead' && lead.leadStatus !== 'Duplicate' && lead.leadStatus !== 'Delete') && (
                <LeadDisplay key={lead.id} lead={lead} hideSensitive={hideSensitive} isMobile={isMobile} />
              )
            ) : (
              <LeadDisplay key={lead.id} lead={lead} hideSensitive={hideSensitive} isMobile={isMobile} />
            )
          ))
        )}
        {isLoading ? (
          <Box style={{ display: 'flex', justifyContent: 'center', margin: '40px 0 20px' }}>
            <Typography style={{ fontSize: '16px', color: '#777' }}>
              Loading ...
            </Typography>
          </Box>
        ) : (
        (leads.length > 0 && lastPageReached === true) ?(
          <Box style={{ display: 'flex', justifyContent: 'center', margin: '40px 0 20px' }}>
            <Typography style={{ fontSize: '16px', color: '#777' }}>
              End of results.
            </Typography>
          </Box>
        ) : (
          leads.length === 0 && (
            <Box style={{ display: 'flex', justifyContent: 'center', margin: '40px 0 20px' }}>
              <Typography style={{ fontSize: '16px', color: '#777' }}>
                No leads matched your query and filter options.
              </Typography>
            </Box>
          )
        )
        )}
      </ContentWidth>
    </Box>
  );
};

export default DashboardPage;
