import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, IconButton, Box, Drawer, useMediaQuery, useTheme, Link, Container } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import PlaceIcon from '@mui/icons-material/Place';
import CloseIcon from '@mui/icons-material/Close';
import Button from './theme/Button';
import styled from 'styled-components';
import SidebarMenu from './theme/SidebarMenu';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const StyledLogo = styled(Link)`
  display: inline-block;
  padding: 4px 4px 0;
  margin-left: -4px !important;
  &:hover{
    background-color: rgba(0,0,0,0.1);
  }
  @media all and (max-width: 599px){
    padding: 0 2px;
  }
`
const CallUsNow =  styled(Link)`
  && {
    display: flex;
    flex-direction: column;
    text-align: right;
    font-weight: 700;
    margin-right: 40px;
    padding: 6px 20px;
    &:hover{
      color: #B9E123;
    }
    @media all and (max-width: 599px){
      margin-right: 12px;
      padding: 4px 2px;
    }
    @media all and (max-width: 349px){
      margin-right: 5px;
    }
  }
`;
const GoogleReviewsButton = styled(Link)`
&& {
  cursor: pointer;
  padding: 0 4px;
  white-space: nowrap;
  margin-right: 10px;
}
`;

const FindUsButton = styled(Link)`
  cursor: pointer;
  padding: 0 4px;
  white-space: nowrap;
  svg{
    fill: #282828;
  }
  @media all and (max-width: 599px){
    svg{
      fill: #464646;
    }
  }
`;

const StyledButton = styled(Button)`
  && {
    margin-right: 40px;
    text-transform: initial;
  }
`;
const StyledIconButton = styled(IconButton)`
  &&{
  &:hover{
    background-color: rgba(0,0,0,0.1);
  }
}
`
const MobileMenuContainer = styled(Box)`
  position: relative;
  width: 100vw;
  height: 100vh;
  max-width: 448px;
  padding: 20px;
  background: rgb(40,40,40);
  box-sizing: border-box;
`
const HeaderReviewContainer = styled(Box)`
  position: relative;
  width: 100vw;
  height: 100vh;
  max-width: 448px;
  padding: 140px 24px 40px;
  background: rgb(240,240,240);
  box-sizing: border-box;
  overflow-y: auto;
  @media all and (max-width: 599px){
    padding: 120px 24px 40px;
  }
`

const CloseMenuWrap = styled(Box)`
  position: relative;
  display: flex;
  padding-top: 22px;
  width: 100%;
  flex-flow: row-reverse;
  box-sizing: border-box;
  padding: 38px 10px 24px;
  @media all and (max-width: 599px){
    padding: 24px 0 12px;
  }
`
const FixedCloseMenuWrap = styled(CloseMenuWrap)`
  position: fixed;
  margin: 0;
  top: 0;
  left: 0;
  z-index: 10;
  transform: translateX(0);
  background: rgb(240,240,240);
  width: 448px!important;
  max-width: 100vw;
  padding: 58px 10px 24px;
  @media all and (max-width: 599px){
    padding: 44px 16px 24px 0;
  }
`;
const CloseMenuButton = styled(IconButton)`
  position: relative;
  width: 48px;
  height: 48px;
  color: white;
`

const StyledMenuLink = styled(Link)`
  &&{
    display: block;
    padding: 14px 14px;
    font-size: 18px;
    height: 22px;
    line-height: 22px;
    cursor: pointer;
    &:hover{
      color: white;
    }
  }
`
const CallUsNowMenu = styled(CallUsNow)`
  flex-align: start;
`
const StyledMenuButton = styled(Button)`
  && {
    font-size: 18px;
    margin-top: 14px;
    margin-left: 12px;
    text-transform: initial;
  }
`;
const LoginUserBox = styled(Box)`
  font-size: 14px;
  margin: 0 20px 0 0;
`

function Header() {
  const { user, isAuthenticated, logout } = useAuth();
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [isVisible, setIsVisible] = useState(true);
  const [isTop, setIsTop] = useState(true);

  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const visible = prevScrollPos > currentScrollPos || currentScrollPos < 4;
      const isTop = currentScrollPos < 4;
      setIsVisible(visible);
      setIsTop(isTop);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPos, isVisible, setIsVisible]);

  const LogoIcon = isMobile ? '/images/logo-icon-mobile.png' : '/images/logo-icon.png';
  const logoWidth = isMobile ? 100 : 147;
  const logoHeight = isMobile ? 37 : 37;

  const googleIcon = '/images/google-g-icon.png';
  const googleStarIcon = '/images/google-star-icon.png'
  

  return (
    <Box className="site-header">
      <AppBar position="fixed" className="header-background" style={{ top: isVisible ? isTop ? '0' : '0' : '-98px', transition: 'top 400ms' }} >
        <Container>
          <Toolbar style={{ padding: 0, minHeight: '54px' }}>
            <Box flexGrow={1}>
              <StyledLogo href="/">
                <img src={LogoIcon} width={logoWidth} height={logoHeight} className="site-logo" alt="Car Buyers Online" />
              </StyledLogo>
            </Box>
            <>
              {isAuthenticated && user && (
                <LoginUserBox>
                  Logged in: {user.name}
                </LoginUserBox>
              )}
              <StyledIconButton edge="end" color="inherit" onClick={() => setOpen(true)} style={{marginRight: '-8px' }}> 
                <MenuIcon style={{ fontSize: 38 }} />
              </StyledIconButton>
            </>
          </Toolbar>
        </Container>
      </AppBar>
      <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
        <MobileMenuContainer>
          <CloseMenuWrap>
            <CloseMenuButton onClick={() => setOpen(false)} >
              <CloseIcon style={{ fontSize: 32, color: 'white'}} />
            </CloseMenuButton>
          </CloseMenuWrap>
          {/* Menu Content: */}
          {isAuthenticated ? (
            <>
              <StyledMenuLink href="/dashboard">Dashboard</StyledMenuLink>
              <StyledMenuLink href="/appointment-times">Opening Hours</StyledMenuLink>
              <StyledMenuLink href="/analytics">Analytics</StyledMenuLink>
              <StyledMenuLink onClick={logout}>Logout</StyledMenuLink>
            </>
          ) : (
            <StyledMenuLink href="/">Login</StyledMenuLink>
          )}
        </MobileMenuContainer>
      </Drawer>
    </Box>
  );
}

export default Header;