import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { ThemeProvider } from '@mui/material/styles';
import { AuthProvider } from './context/AuthContext';
import { ProtectedRoute } from './context/ProtectedRoute';
import theme from './theme';
import Header from './components/Header';
import Footer from './components/Footer';
import LoginPage from './views/LoginPage';
import DashboardPage from './views/DashboardPage';
import AppointmentTimes from './views/AppointmentTimes';
import SetPasswordPage from './views/SetPasswordPage';
import RequestResetTokenPage from './views/RequestResetTokenPage';

import { Analytics } from '@vercel/analytics/react' // Vercel Analytics
import AnalyticsPage from './views/AnalyticsPage';

function App() {
  const [isDarkMode, setIsDarkMode] = useState(false);

  return (
    <AuthProvider>
      <Router>
        <HelmetProvider>
          <ThemeProvider theme={theme}>
            <Header />
            <Routes>
              <Route path="/" element={<LoginPage />} />
              <Route path="/setup-password" element={<SetPasswordPage />} />
              <Route path="/request-reset-token" element={<RequestResetTokenPage />} />
              <Route path="/appointment-times" element={
                <ProtectedRoute>
                  <AppointmentTimes />
                </ProtectedRoute>
              } />
              <Route path="/dashboard" element={
                <ProtectedRoute>
                  <DashboardPage />
                </ProtectedRoute>
              } />
              <Route path="/analytics" element={
                <ProtectedRoute>
                  <AnalyticsPage />
                </ProtectedRoute>
              } />
            </Routes>
            {/* <Footer /> */}
            <Analytics />
          </ThemeProvider>
        </HelmetProvider>
      </Router>
    </AuthProvider>
  );
}

export default App;
