import React, { useEffect, useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Button, SelectChangeEvent, Box } from '@mui/material';
import axios from 'axios';
import styled from 'styled-components';

const FilterOptionsBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
  margin: 0 0 8px;
  @media all and (max-width: 779px){
    flex-direction: column;
  }
`
const FilterLeft = styled(Box)`
  display: flex;
  gap: 10px;
  @media all and (max-width: 779px){
    flex-direction: column;
  }
`
const FilterRight = styled(Box)`
  display: flex;
  gap: 10px;
  @media all and (max-width: 779px){
    flex-direction: column;
  }
`
const MobileTop = styled(Box)`
  display: flex;
  gap: 10px;
`
const MobileBottom = styled(Box)`
  display: flex;
  gap: 10px;
`
const ResetButton = styled(Button)`
  @media all and (max-width: 779px){
    width: 290px;
  }
`

interface FilterOptionsProps {
  onFilterChange: (filters: {
    year?: string;
    make?: string;
    model?: string;
    otherStatus?: string;
    leadStatus?: string;
  }) => void;
  onResetSearchQuery: () => void;
}


export interface MoreFilterOptions {
  value: string;
  label: string;
}

export const statusOptions: MoreFilterOptions[] = [
  { value: 'New', label: 'New' },
  { value: 'Working', label: 'Working' },
  { value: 'Appointment', label: 'Appointment' },
  { value: 'Purchased', label: 'Purchased' },
  { value: 'Dead', label: 'Dead' },
  { value: 'Duplicate/Deleted', label: 'Duplicate/Deleted' },
];

export const otherStatusOptions: MoreFilterOptions[] = [
  { value: 'Is Bookmarked', label: 'Is Bookmarked' },
  { value: 'Has Photos', label: 'Has Photos' },
];

const API_PREFIX = process.env.REACT_APP_SERVER_API_URL;

const FilterOptions: React.FC<FilterOptionsProps> = ({ onFilterChange, onResetSearchQuery }) => {
  const [allYears, setAllYears] = useState<string[]>([]);
  const [allMakes, setAllMakes] = useState<string[]>([]);
  // const [displayYears, setDisplayYears] = useState<string[]>([]);
  // const [displayMakes, setDisplayMakes] = useState<string[]>([]);
  const [models, setModels] = useState<string[]>([]);
  const [selectedYear, setSelectedYear] = useState('');
  const [selectedMake, setSelectedMake] = useState('');
  const [selectedModel, setSelectedModel] = useState('');

  const [selectedLeadStatus, setSelectedLeadStatus] = useState('');
  const [selectedOtherStatus, setSelectedOtherStatus] = useState('');

  // Fetch initial options
  useEffect(() => {
    const fetchFilterOptions = async () => {
      try {
        const token = localStorage.getItem('token');
        const { data } = await axios.get(`${API_PREFIX}/filter-options`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setAllYears(data.years || []);
        setAllMakes(data.makes || []);
        // setDisplayYears(data.years || []);
        // setDisplayMakes(data.makes || []);
      } catch (error) {
        console.error('Failed to fetch filter options', error);
      }
    };

    fetchFilterOptions();
  }, []);

  const fetchModels = async (year: string, make: string) => {
    try {
      const token = localStorage.getItem('token');
      const { data } = await axios.get(`${API_PREFIX}/fetch-models`, {
        headers: { Authorization: `Bearer ${token}` },
        params: { year, make }
      });
      setModels(data.models || []);
    } catch (error) {
      console.error('Failed to fetch models', error);
    }
  };

  useEffect(() => {
    if (selectedYear && selectedMake) {
      fetchModels(selectedYear, selectedMake);
    } else {
      setModels([]);
    }
  }, [selectedYear, selectedMake]);
  
  const handleYearChange = (event: SelectChangeEvent) => {
    const newYear = event.target.value as string;
    setSelectedYear(newYear);
    onFilterChange({ year: newYear, make: selectedMake, model: selectedModel, leadStatus: selectedLeadStatus, otherStatus: selectedOtherStatus });
  };
  
  const handleMakeChange = (event: SelectChangeEvent) => {
    const newMake = event.target.value as string;
    setSelectedMake(newMake);
    onFilterChange({ year: selectedYear, make: newMake, model: selectedModel, leadStatus: selectedLeadStatus, otherStatus: selectedOtherStatus });
  };
  
  const handleModelChange = (event: SelectChangeEvent) => {
    const newModel = event.target.value as string;
    setSelectedModel(newModel);
    onFilterChange({ year: selectedYear, make: selectedMake, model: newModel, leadStatus: selectedLeadStatus, otherStatus: selectedOtherStatus });
  };

  const handleStatusChange = (event: SelectChangeEvent) => {
    const newLeadStatus = event.target.value;
    setSelectedLeadStatus(newLeadStatus);
    onFilterChange({ year: selectedYear, make: selectedMake, model: selectedModel, leadStatus: newLeadStatus, otherStatus: selectedOtherStatus });
  };

  const handleOtherStatusChange = (event: SelectChangeEvent) => {
    const newOtherStatus = event.target.value;
    setSelectedOtherStatus(newOtherStatus);
    onFilterChange({ year: selectedYear, make: selectedMake, model: selectedModel, leadStatus: selectedLeadStatus, otherStatus: newOtherStatus });
  };

  const handleReset = () => {
      setSelectedYear('');
      setSelectedMake('');
      setSelectedModel('');
      setSelectedLeadStatus('');
      setSelectedOtherStatus('');
      onFilterChange({ year: '', make: '', model: '', leadStatus: '', otherStatus: '' });
      onResetSearchQuery();
  };

  return (
    <FilterOptionsBox>
      <FilterLeft>
        <MobileTop>
          <FormControl sx={{ height: 52, minWidth: 100 }}>
            <InputLabel id="year-select-label">Year</InputLabel>
            <Select
              labelId="year-select-label"
              id="year-select"
              value={selectedYear}
              label="Year"
              onChange={(event) => handleYearChange(event)}
              sx={{ minHeight: 52, lineHeight: '54px' }}
            >
              <MenuItem key="clear" value=""><i>All</i></MenuItem>
              {allYears.map((year) => (
                <MenuItem key={year} value={year} style={{ alignContent: 'center' }}>{year}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ height: 52, minWidth: 180 }}>
            <InputLabel id="make-select-label">Make</InputLabel>
            <Select
              labelId="make-select-label"
              id="make-select"
              value={selectedMake}
              label="Make"
              onChange={(event) => handleMakeChange(event)}
              sx={{ minHeight: 52, lineHeight: '44px'  }}
            >
              <MenuItem key="clear" value=""><i style={{ color: 'grey' }}>All</i></MenuItem>
              {allMakes.map((make) => (
                <MenuItem key={make} value={make}>{make}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </MobileTop>
        {models.length > 0 && (
          <MobileBottom>
            <FormControl sx={{ height: 52, minWidth: 180 }}>
              <InputLabel id="model-select-label" style={{ background: 'white', padding: '0 8px' }}>Model</InputLabel>
              <Select
                labelId="model-select-label"
                id="model-select"
                value={selectedModel}
                onChange={(event) => handleModelChange(event)}
                sx={{ minHeight: 52, lineHeight: '44px' }}
              >
                <MenuItem key="clear" value=""><i style={{ color: 'grey' }}>All</i></MenuItem>
                {models.map(model => <MenuItem key={model} value={model}>{model}</MenuItem>)}
              </Select>
            </FormControl>
          </MobileBottom>
        )}
      </FilterLeft>
      <FilterRight>
        <MobileTop>
          <FormControl sx={{ height: 52, minWidth: 140 }}>
            <InputLabel id="other-status-label">Other Status</InputLabel>
            <Select
              id="other-status-select"
              value={selectedOtherStatus}
              label="Other Status"
              onChange={handleOtherStatusChange}
              sx={{ minHeight: 52, lineHeight: '44px' }}
            >
              <MenuItem key="clear" value=""><i style={{ color: 'grey' }}>All</i></MenuItem>
              {otherStatusOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ height: 52, minWidth: 140 }}>
            <InputLabel id="lead-status-label">Lead Status</InputLabel>
            <Select
              id="lead-status-select"
              value={selectedLeadStatus}
              label="Lead Status"
              onChange={handleStatusChange}
              sx={{ minHeight: 52, lineHeight: '44px' }}
            >
              <MenuItem key="clear" value=""><i style={{ color: 'grey' }}>Default</i></MenuItem>
              {statusOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </MobileTop>
        <MobileBottom>
          <ResetButton onClick={handleReset} style={{ margin: '0 0 10px 0', height: 50, lineHeight: '44px', border: '1px solid' }}>Reset Filters</ResetButton>
        </MobileBottom>
      </FilterRight>
    </FilterOptionsBox>
  );
};

export default FilterOptions;