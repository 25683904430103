import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { format } from 'date-fns';

// Extend dayjs with the custom parse format plugin
dayjs.extend(customParseFormat);


// Utility function to format the date
export const formatDate = (date: Date) => {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  const isToday = date.toDateString() === today.toDateString();
  const isYesterday = date.toDateString() === yesterday.toDateString();

  if (isToday) {
    return 'Today';
  } else if (isYesterday) {
    return 'Yesterday';
  } else {
    // Format date as "dd/mm/yyyy"
    return date.toLocaleDateString("en-AU");
  }
};

export const formatTime = (date?: Date) => {
  // Format time as "hh:mm AM/PM"
  return date?.toLocaleTimeString("en-AU", { hour: '2-digit', minute: '2-digit', hour12: true });
};

export function reformatDate(dateString: string) {
    return dayjs(dateString, 'YYYY-MM-DD').format('DD/MM/YYYY');
};

export const formatHumanReadableDate = (date: string): string => {
  const parsedDate = new Date(date);

  // Check if the parsed date is valid
  if (isNaN(parsedDate.getTime())) {
    // Return a fallback message or empty string if the date is invalid
    return 'Invalid date';
  }

  // If the date is valid, format it
  return format(parsedDate, "MMM do");
};

export const convertTimeToDays = (timeSinceEnquiry: number): string => {
  // Convert milliseconds to days
  const days = Math.floor(timeSinceEnquiry / (1000 * 60 * 60 * 24));

  // Return the result in a human-readable format
  if (days === 0) {
    return "Today";
  } else if (days === 1) {
    return "1 day ago";
  } else {
    return `${days} days ago`;
  }
};