// Manual email templates for the email selector

import { formatHumanReadableDate } from '../utils/formatDateTimeUtils';

export const emailTemplates = (
    firstName: string, 
    appointmentDate: string | undefined,
    appointmentTime: string | undefined,
    make: string | undefined,
    model: string | undefined,
    fromName: string | undefined,
    eid: string | undefined
  ) => {
    const formattedDate = appointmentDate ? formatHumanReadableDate(appointmentDate) : '[DATE NOT SET]';
    const formattedTime = appointmentTime ? appointmentTime : '[TIME NOT SET]';
    
    // Note signature is not currently used due to text limits on mailto links
    const signature = `\r\n
    Kind Regards,\r\n
    ${fromName ?? ''}\r\n
    Car Buyers Online\r\n
    Ph: 07 3868 4683\r\n
    Fax: 07 3868 1014\r\n
    787 Kingsford Smith Drive, Eagle Farm QLD 4009,\r\n
    PO BOX 1006, Hamilton QLD 4007\r\n
    Website: https://carbuyersonline.com.au \r\n
    `;
      
    return[
      {
        label: 'Follow Up - First Contact',
        subject: 'Can we help? - CarBuyers Online',
        body: `Hi ${firstName},\r\n
Thank you for enquiring about selling your vehicle with CarBuyers Online. We would love to help you sell your vehicle and give you an easy and hassle-free experience!\r\n
Being a computer-based system, our website is only able to provide an automated estimate and with market pricing and demand changing daily, we would love the opportunity to discuss your vehicle with you to achieve a more accurate offer.\r\n
I thought I would just send through our valuation process: \r\n
One of our valuers can give you an obligation-free valuation. When we agree on a price, you or your finance company will be paid instantly. We take the car off your hands for you, with no need for a Roadworthy and we complete all registration transfers.\r\n
Our office location is - 787 Kingsford Smith Drive, Eagle Farm. Though we can come to you if you would like to send through some photos of your vehicle so we can provide you with a valuation specific to your car and be in touch. You are more than welcome to send the photos in reply to this email or if text is more convenient, our number is 0412 247 887.\r\n
Please do not hesitate to contact me if you would like to book an appointment for a valuation.\r\n
`
      },
      {
        label: 'Follow Up - Photo Upload Link',
        subject: 'Can we help? - CarBuyers Online',
        body: `Hi ${firstName},\r\n
Thank you for enquiring on selling your vehicle with CarBuyers Online, we would love to help you sell your vehicle and give you an easy and hassle free experience!\r\n
Being a computer based system, our website is only able to provide an automated estimate and with market pricing and demand changing daily, we would love the opportunity to discuss your vehicle further with you to achieve a more accurate offer on your vehicle.\r\n
I thought I would just send through our valuation process to you so you are aware of the process:\r\n
One of our valuers can give you an obligation-free valuation as to what we are able to offer you. When we agree on a price you/your finance company will be paid instantly and we take the car off of your hands for you with no need for a Roadworthy and we complete all registration transfers.\r\n
Our office location is:\r\n
787 Kingsford Smith Drive, Eagle Farm, alternatively we can come to you if would you like to send through some photos of your vehicle so we can provide you with a valuation specific to your car and be in touch.\r\n
You are more than welcome to send the photos in reply to this email or please upload to your enquiry using the link: https://carbuyersonline.com.au/image-upload?id=${eid} \r\n
Please do not hesitate to contact me if you would like to book in an appointment for a valuation.\r\n
`
      },
      {
        label: 'Follow Up - 3+ Week',
        subject: 'Have You Sold Your Car Yet? - CarBuyers Online',
        body: `Hi ${firstName},\r\n
Have you sold your ${make ?? ``} ${model ?? `vehicle`} yet? If not, we’d love the opportunity to perform a personalized valuation and make you an offer.\r\n
It's been a couple of weeks since you first enquired with CarBuyers Online. We’d like to see your car sold quickly and efficiently with our hassle-free service. Being a computer-based automated system and with market pricing and demand changing daily, we would love the opportunity to discuss your vehicle further with you to provide you with the most accurate offer for your vehicle.\r\n
We appreciate all feedback as we aim to be the most reputable and trustworthy vehicle buying service.\r\n
If you have some feedback, we'd love to hear from you. If you would like to book an appointment, please reply to this email and we’ll get in touch. Alternatively, we can come to you if you would like to send through some photos of your vehicle so we can provide you with a valuation specific to your car and be in touch. You are more than welcome to send the photos in reply to this email or if text is more convenient, our number is 0412247887.\r\n
Thank you again, I look forward to hearing from you soon.\r\n
`
      },
      {
        label: 'Appointment - Confirmation (In Office)',
        subject: 'Appointment Confirmation - CarBuyers Online',
        body: `Hi ${firstName},\r\n
Thank you very much for booking your vehicle with CarBuyers Online.\r\n
Your appointment is currently booked at ${formattedTime} on ${formattedDate} with Samm at our Eagle Farm location - 787 Kingsford Smith Drive, Eagle Farm. Please reply if any of these details are incorrect. \r\n
I want to make your experience with CarBuyers Online as smooth as possible, so I have provided this brief checklist of things to bring with you: \r\n
- Identification (drivers licence)\r\n
- Registration Certificate/Registration renewal document. If you are unable to locate your certificate, you can download it through the QLD Transport portal link: https://www.service.transport.qld.gov.au/getregistrationcertificate/public/Welcome.xhtml?dswid=-1085\r\n
- Personal payment details (eg. bank details)\r\n
- Payout details - if your vehicle is under finance (we must have a current payout letter from your financier)\r\n
Thank you again and we look forward to seeing you for the appointment. If you need help with directions, please feel free to call. Otherwise, we are located at 787 Kingsford Smith Drive, Eagle Farm.\r\n
`
      },
      {
        label: 'Appointment - Out of Office',
        subject: 'Out of Office - CarBuyers Online',
        body: `Hi ${firstName},\r\n
I just thought I would touch base regarding your ${make ?? ``} ${model ?? `vehicle`}. I have tried to call you regarding your appointment request.\r\n
Would you mind returning my call on 07 3868 4683 so that we can discuss the vehicle and be able to confirm the appointment?\r\n
Thank you again and looking forward to speaking with you.\r\n
`
      },
      {
        label: 'Appointment - Request Photos (Remote Location)',
        subject: 'Vehicle Photos - CarBuyers Online',
        body: `Hi ${firstName},\r\n
I just thought I would touch base regarding your appointment request for your ${make ?? ``} ${model ?? `vehicle`}. As we are a Brisbane based business we would like to get some pictures from you so that we can give you a price remotely before the appointment as we don’t have any offices nearby just yet, but we do still service your area normally.\r\n
Would you mind sending me through some pictures and more info on the vehicle so that we can start the process?\r\n
You can upload photos using the link: https://carbuyersonline.com.au/image-upload?id=${eid} \r\n
`
      },
      {
        label: 'Feedback Request',
        subject: 'Feedback - CarBuyers Online',
        body: `Hi ${firstName},\r\n
Thank you very much again for your time. It was great working with you.\r\n
As mentioned, if you have the time, we would love to receive a review of your experience with CarBuyers Online via Google: https://g.page/r/Cchcu4gpQYrhEAI/review\r\n
We appreciate all feedback as we aim to be the most reputable and trustworthy vehicle buying service.\r\n
Thank you again and if there is anything else we can do to help please don’t hesitate to ask.\r\n
Thank you again and have a lovely day.\r\n
`
    }
  ];
};


// SMS templates
export const smsTemplates = (
  firstName: string, 
  appointmentDate: string | undefined,
  appointmentTime: string | undefined,
  make: string | undefined,
  model: string | undefined,
  fromName: string | undefined,
  eid: string | undefined
) => {
  const formattedDate = appointmentDate ? formatHumanReadableDate(appointmentDate) : '[DATE NOT SET]';
  const formattedTime = appointmentTime ? appointmentTime : '[TIME NOT SET]';

  return [
    {
      label: 'Intro - Photo Request',
      body: `Hi ${firstName}, thanks for sending your vehicle details to Car Buyers Online! We’re here to help make your sale easy. Please upload photos via this link: https://carbuyersonline.com.au/image-upload?id=${eid}`
    },
    {
      label: 'Intro - HAS Photos',
      body: `Hi ${firstName}, thanks for sending your vehicle details and photos to Car Buyers Online! We’re here to help make your sale easy. We will get back to you shortly. If your sale is urgent, please call us on 1300 880 118.`
    },
    {
      label: 'Follow Up - Photo Upload Link',
      body: `Hi ${firstName}, to help us give you an accurate offer, please upload photos of your vehicle using this link: https://carbuyersonline.com.au/image-upload?id=${eid}`
    },
    {
      label: 'Appointment - Confirmation',
      body: `Hi ${firstName}, just a reminder of your upcoming appointment with Car Buyers Online at ${formattedTime} on ${formattedDate}. Please let us know ASAP, if you need to reschedule.`
    },
    {
      label: 'Feedback Request',
      body: `Hi ${firstName}, thank you for working with Car Buyers Online! We would greatly appreciate your feedback, please leave a review of your experience here: https://g.page/r/Cchcu4gpQYrhEAI/review`
    }
  ];
};