import React from 'react';
import { Box, Button } from '@mui/material';
import { ContentWidth } from '../components/ThemeComponents';
import AnalyticsOverview from '../components/theme/AnalyticsOverview';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';

const AnalyticsPage: React.FC = () => {

  return (
    <Box>
      <ContentWidth>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '15px' }}>
          <h3 style={{ color: '#282828', margin: '10px 0 0' }}>Analytics</h3>
          <Button
            href="/"
            variant="outlined"
            startIcon={<ArrowBackIcon />}
            sx={{ marginTop: '4px', marginLeft: '10px', }}
        >
            Back
        </Button>
        </div>
        <AnalyticsOverview />
      </ContentWidth>
    </Box>
  );
};

export default AnalyticsPage;
